
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import type { NextPage } from 'next'
import Layout from "../components/common/Layout";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Link from "next/link"
import {GetStaticPropsContext} from "next";
import storevio from "@lib/api";
import useTranslation from "next-translate/useTranslation";

 async function _getStaticProps({locale}: GetStaticPropsContext) {

    const topNavLinks = await storevio.getMenu(locale!,'top')
    const bottomNavLinks = await storevio.getMenu(locale!,'bottom')
    const settings = await storevio.getSettings(locale!);

    return {
        props: {
            topNavLinks,
            bottomNavLinks,
            settings,
        },
        revalidate: 60,
    }
}

export default function Custom404() {

    const { t } = useTranslation('common');
    return (
          <div className="page-wrapper">

              <div className="cart-thankyou">
                  <div className="container">
                      <p>
                          {t("404_1")}
                          <br/>
                          <br />
                          {t("404_2")}<br />
                          {t("404_3")}
                          <br/>
                      </p>
                      <Link href={"/"}>
                          <a className="btn btn-background btn-arrow-right">
                              {t("Návrat na titulní stranu")}
                          </a>
                      </Link>
                  </div>
              </div>

          </div>
  )
}

Custom404.Layout = Layout


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  